import isImageUrl from 'is-image-url';
import { parse as videoURLParse } from 'js-video-url-parser';
import hash from 'object-hash';
import { NullableFTS } from '@lib/utils';
// const videoURLParse = require('js-video-url-parser')

export enum MediaPayloadType {
  IMAGE = 'IMAGE',
  VIDEO = 'VIDEO',
  TEXT = 'TEXT'
}

export type MediaObject = {
  id?: string;
  ts?: NullableFTS;
  url: string;
  type: MediaPayloadType;
  name?: string;
  text?: string;
  size?: number;
};

export const getMediaObjectHash = ({ url, type }: MediaObject) =>
  hash({ url, type });

export type MediaIndex = {
  [key in MediaPayloadType]: MediaObject[];
};

export const guessURLMediaType = (url: string) => {
  const videoParseAttempt = videoURLParse(url);
  if (videoParseAttempt?.mediaType === 'video') {
    return MediaPayloadType.VIDEO;
  }
  if (isImageUrl(url)) {
    return MediaPayloadType.IMAGE;
  }
  return MediaPayloadType.IMAGE;
};

export const loadImage = (
  path: string
): Promise<[string, HTMLImageElement]> => {
  return new Promise((resolve, reject) => {
    const img = new Image();
    img.onload = function () {
      resolve([img.src, img]);
    };
    img.src = path;
  });
};

const CLOUDINARY_ACCOUNT_NAME = 'dfx4tamtl';
const PROGRESSIVE_FLAGS = `fl_progressive:semi,f_jpg`;
const PROGRESSIVE_PREFIX = `https://res.cloudinary.com/${CLOUDINARY_ACCOUNT_NAME}/image/fetch/${PROGRESSIVE_FLAGS}/`;
const MIDSIZE_FLAGS = `w_400,f_jpg,fl_keep_iptc`;
const MIDSIZE_PREFIX = `https://res.cloudinary.com/${CLOUDINARY_ACCOUNT_NAME}/image/fetch/${MIDSIZE_FLAGS}/`;

export const getProgressive = (url: string) => {
  return `${PROGRESSIVE_PREFIX}${url}`;
};

export const getMidsize = (url: string) => {
  return `${MIDSIZE_PREFIX}${url}`;
};

export const isImageURLSecure = (url?: string) => {
  if (url?.startsWith('http:')) {
    return false;
  }
  return true;
};

export const getSecureImageURL = (url: string) => {
  return isImageURLSecure(url) ? url : `${MIDSIZE_PREFIX}${url}`;
};
