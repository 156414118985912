import React, { useState, useRef, useContext } from 'react';
import styled from '@lib/styled';
import Button from '@components/atoms/Button';
import { ActionTile } from '@components/molecules/MediaTile';
import {
  AppstoreOutlined,
  SplitCellsOutlined,
  SoundOutlined,
  HighlightOutlined
} from '@ant-design/icons';
import { useWindowWidth } from '@hooks/useWindow';
import { Typography, Space } from 'antd';
const { Text } = Typography;

const iconProps = size => ({
  style: { fontSize: size * 0.4, color: 'white' }
});

export default function Targets(props: any) {
  const windowWidth = useWindowWidth({ wait: 100 });
  const size = windowWidth
    ? Math.min(Math.max((windowWidth - 200) / 5, 100), 90)
    : 74;
  return (
    <NewCardContainer>
      <TilesContainer>
        <ActionTile
          size={size}
          content={
            <ButtonContainer>
              <SoundOutlined {...iconProps(size)} />
              <Label>Listen</Label>
            </ButtonContainer>
          }
        />
        <ActionTile
          size={size}
          content={
            <ButtonContainer>
              <SplitCellsOutlined {...iconProps(size)} />
              <Label>Sort</Label>
            </ButtonContainer>
          }
        />
        <ActionTile
          size={size}
          content={
            <ButtonContainer>
              <AppstoreOutlined {...iconProps(size)} />
              <Label>Match</Label>
            </ButtonContainer>
          }
        />
        <ActionTile
          size={size}
          content={
            <ButtonContainer>
              <HighlightOutlined {...iconProps(size)} />
              <Label>Indpt.</Label>
            </ButtonContainer>
          }
        />
      </TilesContainer>
    </NewCardContainer>
  );
}

const ButtonContainer = styled.div`
  margin-top: 12px;
  display: flex;
  flex-direction: column;
  justify-content: center;
`;

const Label = props => <T {...props} />;

const TilesContainer = styled.div`
  vertical-align: top;
  /* display: flex; */
  flex: 1;
  width: 90%;
  /* flex-flow: row wrap; */
  /* flex-wrap: wrap; */
  /* align-items: stretch; */
  /* justify-content: flex-start; */
  min-width: 220px;
  display: grid;
  grid-gap: 1rem;
  grid-template-columns: repeat(auto-fit, minmax(80px, 1fr));
  /* -webkit-box-shadow: inset 0px 7px 32px -20px rgba(0, 0, 0, 0.52);
  -moz-box-shadow: inset 0px 7px 32px -20px rgba(0, 0, 0, 0.52);
  box-shadow: inset 0px 7px 32px -20px rgba(0, 0, 0, 0.52); */
`;

const T = styled(Text)`
  text-align: center;
  margin-bottom: 4px;
  font-size: 13px;
  margin-top: 4px;
  color: white;
`;

const NewCardContainer = styled.div`
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: space-between;
  flex: 15;
`;
