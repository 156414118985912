import firebase from '@lib/firebase';
import useAuth from '@hooks/useAuth';
const COLLECTION_NAME = 'communicationLeads';

export default function useMarketingIngestion() {
  const collectionRef = firebase.firestore().collection(COLLECTION_NAME);
  const { user } = useAuth();
  const ingestEmail = async (email: string) => {
    return collectionRef.add({
      email,
      ts: firebase.firestore.FieldValue.serverTimestamp(),
      userUID: user?.uid || 'na'
    });
  };

  const ingestToWaitlist = async (payload: any) => {
    return collectionRef.add({
      ...payload,
      type: 'WAITLIST',
      ts: firebase.firestore.FieldValue.serverTimestamp(),
      userUID: user?.uid || 'na'
    });
  };
  return {
    ingestEmail,
    ingestToWaitlist
  };
}
