import React from 'react';
import Button from '@components/atoms/Button';
import {
  DeleteOutlined,
  PlusOutlined,
  PlusSquareOutlined,
  UploadOutlined
} from '@ant-design/icons';
import { Typography } from 'antd';
import { string } from 'prop-types';
import styled from '@lib/styled';
import { Image, AspectRatioBox, Box } from '@chakra-ui/core';
import Tile, { SizeProps } from '@components/atoms/Tile';
const { Text, Title } = Typography;
import { primaryColor, grayscaleFilter } from '@lib/theme';
import { MediaPayloadType, getSecureImageURL } from '@lib/media';
import ReactPlayer from 'react-player';

const getFontSizeMultiplier = (text: string) => {
  if (!text) {
    return 1.5;
  }

  if (text.length < 10) {
    return 1.5;
  }

  if (text.length < 20) {
    return 1;
  }

  if (text.length < 40) {
    return 0.8;
  }

  if (text.length < 60) {
    return 0.5;
  }
  return 0.3;
};

export default function TextTileContent({ item, size }) {
  const { text } = item;
  const x = getFontSizeMultiplier(text);
  const size_ = ((item?.size / 50 || x) * size) / 4;

  return (
    <Container>
      <Text style={{ width: size - 10, fontWeight: 'bold', fontSize: size_ }}>
        {text}
      </Text>
    </Container>
  );
}

const Container = styled.div`
  display: flex;
  flex: 1;
  height: 100%;
  width: 100%;
  flex-direction: column;
  justify-content: center;
  align-items: stretch;
  padding: 2%;
  line-height: 1.1;
`;
