import React, { useState } from 'react';
import styled from '@lib/styled';
import {
  FacebookOutlined,
  RightCircleOutlined,
  MailOutlined
} from '@ant-design/icons';
import Header from '@atoms/Header';
import Footer from '@atoms/Footer';
import TargetTypes from './TargetTypes';
import ReactPlayer from 'react-player';
import { Space, Typography, Divider, Anchor } from 'antd';
import Button from '@components/atoms/Button';
import { Image, Collapse } from '@chakra-ui/core';
import brand from '@lib/brand';
import { primaryColor } from '@lib/theme';
import horse_manage_right from '@images/horse_manage_right.png';
import horse_batman_tokens from '@images/horse_batman_tokens.png';
import ipad_create_targets from '@images/ipad_create_targets.png';
import ipad_coins_sort from '@images/ipad_coins_sort.png';
import SubscribeMail from '@molecules/SubscribeMail';

const { Text, Title } = Typography;
function Home() {
  const [showEmail, setShowEmail] = useState(false);
  return (
    <>
      <Header />
      <Container>
        <Row>
          <DetailsContainer>
            <IntroBox>
              <Title style={{ margin: 0 }}>Digital Sessions That Work</Title>
              <Title style={{ marginTop: 10 }}>
                Provided as a public service
              </Title>
              <TextStyled>
                Real-time activities, data collection, video chat, and more.
                Made with love by volunteers who care. Global compliance
                ready--HIPAA and beyond.
              </TextStyled>
              <Button
                icon={<RightCircleOutlined />}
                style={{ width: 240, marginBottom: 10 }}
                // shape="round"
                type="primary"
                size="large"
                href="/manage">
                Get Started
              </Button>
              <Button
                icon={<FacebookOutlined />}
                style={{ width: 240 }}
                // shape="round"
                type="primary"
                size="large"
                ghost
                href="https://www.facebook.com/groups/668891769907036/">
                Join Community
              </Button>
              <Title level={2} style={{ marginTop: 60 }}>
                Sign up for updates!
              </Title>
              <TextStyled>
                Stay up to date with the latest features at OpenABA.
              </TextStyled>
              <SubscribeMail />
            </IntroBox>
          </DetailsContainer>

          <MediaBoxTop>
            <FirstChatRounder
              style={{
                width: '100%',
                padding: 20
              }}>
              <Title level={2} style={{ margin: 0, color: primaryColor(1) }}>
                New Feature:
              </Title>
              <Title level={2} style={{ margin: 0 }}>
                Report Generation
              </Title>
              <TextStyled>
                Get a session report in just one click. Charts and tables, all
                PDF-friendly!
              </TextStyled>
              <br />
              <ReactPlayer
                width={'100%'}
                controls={true}
                url="https://youtu.be/nXfn5auL6Jw"
              />
            </FirstChatRounder>
          </MediaBoxTop>
        </Row>

        <Divider />

        <Section>
          <RowHeader>
            <Title style={{ margin: 0 }}>
              {brand.brand}: A Telehealth Solution for ABA
            </Title>
            <Divider />
            <TextStyled>
              Real-time activities. High-performance videochat. Data collection.
              Everything in one browser tab. Seamless, fast, easy.
            </TextStyled>
          </RowHeader>
          <RowR>
            <MediaBox>
              <LaptopPair />
            </MediaBox>
          </RowR>
        </Section>

        <Section>
          <RowHeader>
            <Title style={{ margin: 0 }}>Fully Customizable</Title>
            <Divider />
          </RowHeader>
          <RowR>
            <MediaBox>
              <Image
                style={{ minHeight: '20vh' }}
                size="70%"
                objectFit="contain"
                src={ipad_coins_sort}
                alt="Tokens on iPad"
              />
            </MediaBox>
            <TextBox>
              <TextStyled>
                Several customizable activities. Live interactive token boards.
                Reusable media. Automated and editable reports.
              </TextStyled>
              <br />
              <TargetTypes />
            </TextBox>
          </RowR>
        </Section>

        <Section>
          <RowHeader>
            <Title style={{ margin: 0 }}>And So Much More</Title>
            <Divider />
          </RowHeader>
          <RowR>
            <TextBox>
              <TextStyled>
                We are helping everyone deliver the best ABA telehealth
                experience for clients, BCBA, RBTs, and educators.
                <br />
                <br />
                For frequently asked questions, visit{' '}
                <a href="/faq">our FAQ page</a>.
                <br />
                <br />
                For upcoming features, visit{' '}
                <a href="/faq">our product release schedule</a>.
                <br />
                <br />
                To learn more, contact us below.
              </TextStyled>
              <br />
              <Button
                icon={<RightCircleOutlined />}
                style={{ width: 240 }}
                // shape="round"
                size="large"
                type="primary"
                href="/manage">
                Get Started
              </Button>

              <Collapse mt={1} isOpen={showEmail}>
                <Text style={{ margin: 0 }}>Email us at</Text>
                <Title level={4} style={{ margin: 0 }} copyable>
                  {brand.team_email}
                </Title>
                <br />
              </Collapse>
              <br />
              <Button
                ghost
                icon={<MailOutlined />}
                style={{ width: 240 }}
                // shape="round"
                size="large"
                type="primary"
                onClick={() => setShowEmail(true)}
                href={`mailto:${brand.team_email}`}>
                Contact Us
              </Button>
              <br />
              <Button
                icon={<FacebookOutlined />}
                style={{ width: 240 }}
                // shape="round"
                size="large"
                type="primary"
                ghost
                href="https://www.facebook.com/groups/668891769907036/">
                Visit Community Page
              </Button>
            </TextBox>
            <MediaBox>
              <img
                style={{ width: '90%', margin: 'auto', marginBottom: 20 }}
                // size="80%"
                // objectFit="contain"
                src={ipad_create_targets}
                alt="create targets on iPad"
              />
            </MediaBox>
          </RowR>
        </Section>
      </Container>

      <Divider />
      <Footer />
    </>
  );
}

const LaptopPair = () => (
  <LaptopPairRow>
    <img
      style={{ minHeight: '10vh', width: '47%' }}
      src={horse_manage_right}
      alt="manage session screenshot"
    />
    <img
      style={{ minHeight: '10vh', width: '47%' }}
      src={horse_batman_tokens}
      alt="tokens in action"
    />
  </LaptopPairRow>
);

const LaptopPairRow = styled.div`
  display: flex;
  flex-direction: row;
  max-width: 100%;
  overflow: hidden;
  justify-content: space-around;
  flex: 1;
`;

const Container = styled.div`
  display: flex;
  flex-direction: column;
  align-items: stretch;
  justify-content: center;
  min-height: 90vh;
  width: 100%;
  flex: 1;
`;

const IntroBox = styled.div`
  display: flex;
  flex-direction: column;
  justify-content: flex-start;
  align-items: stretch;
  padding: 10px;
  max-width: 600px;
  flex: 1;
  overflow: hidden;
  margin-bottom: 40px; /* required for mobile; otherwise it collides with MediaBox shadow */
`;

const Row = styled.div`
  display: flex;
  flex-direction: row;
  align-items: stretch;
  justify-content: space-between;
  flex-wrap: wrap;
  flex: 1;
  margin-top: 5%;
  margin-bottom: 5%;
`;

const RowR = styled.div`
  display: flex;
  flex-direction: row;
  align-items: stretch;
  justify-content: space-between;
  flex-wrap: wrap-reverse;
  flex: 1;
`;

const MediaBox = styled.div`
  display: flex;
  min-width: 280px;
  margin: 15px;
  align-items: stretch;
  justify-content: center;
  flex: 1;
  flex-direction: column;
`;

// const MediaBox = styled.div`
//   display: flex;
//   min-width: 300px;
//   margin: 15px;
//   align-items: center;
//   justify-content: center;
//   flex: 1;
//   flex-direction: column;
// `;

const RowHeader = styled.div`
  display: flex;
  flex-direction: column;
  width: 100%;
  flex: 1;
`;

const TextBox = styled.div`
  display: flex;
  flex-direction: column;
  min-width: 300px;

  flex: 1;
`;

const TextStyled = styled.div`
  font-size: 18px;
  font-weight: 300;
  padding-bottom: 20px;
`;

const Section = styled.div`
  display: flex;
  flex-direction: column;
  align-items: flex-start;
  justify-content: flex-start;
  padding-left: 7vw;
  padding-right: 7vw;
  padding-top: 5vh;
  max-width: 1200px;
  margin: 10px auto;
  min-height: 42vh;
  flex: 1;
`;

const DetailsContainer = styled.div`
  display: flex;
  flex-direction: column;
  align-items: flex-start;
  justify-content: flex-start;
  padding-left: 4vw;
  padding-right: 4vw;
  /* max-width: 1200px; */
  /* margin: 10px auto; */
  flex: 1;
`;

const MediaBoxTop = styled.div`
  display: flex;
  min-width: 280px;
  align-items: stretch;
  flex-direction: column;
  justify-content: flex-start;
  flex: 1;
  flex-wrap: wrap;
  margin-left: 4vw;
  margin-right: 4%;
`;

const FirstChatRounder = styled.div`
  border-radius: 15px;
  display: flex;
  flex-direction: column;
  justify-content: flex-start;
  overflow: hidden;
  min-width: 100%;
  min-height: 180px;
  -webkit-box-shadow: -4px -4px 41px -14px rgba(0, 0, 0, 0.75);
  -moz-box-shadow: -4px -4px 41px -14px rgba(0, 0, 0, 0.75);
  box-shadow: -4px -4px 41px -14px rgba(0, 0, 0, 0.75);
`;

export default Home;
