import { useEffect } from 'react';
import useDebounce from '@react-hook/debounce';

type UseWindowOption = {
  wait: number;
  leading?: boolean;
};

const defaultOptions = {
  wait: 250,
  leading: false
};

const useSizeHook = (dim: string, options?: UseWindowOption) => {
  const { wait, leading } = options || defaultOptions;
  const docStatus = typeof document !== 'undefined';
  const [size, setDebouncedSize] = useDebounce(
    // @ts-ignore
    docStatus ? document.documentElement[dim] : 0,
    wait,
    leading
  );

  useEffect(() => {
    // @ts-ignore
    const setSize = () => setDebouncedSize(document.documentElement[dim]);
    document.addEventListener('readystatechange', setSize);
    window.addEventListener('resize', setSize);
    window.addEventListener('orientationchange', setSize);

    return () => {
      document.removeEventListener('readystatechange', setSize);
      window.removeEventListener('resize', setSize);
      window.removeEventListener('orientationchange', setSize);
    };
  }, [dim, setDebouncedSize]);

  return size;
};

export const useWindowHeight = (options?: UseWindowOption) =>
  useSizeHook('clientHeight', options);
export const useWindowWidth = (options?: UseWindowOption) =>
  useSizeHook('clientWidth', options);
export const useWindowSize = (options?: UseWindowOption) => [
  useWindowWidth(options),
  useWindowHeight(options)
];

export default useWindowSize;
