import React from 'react';
import FadeIn from '@components/atoms/FadeIn';
import Home from '@containers/Home';
import brand from '@lib/brand';
import Head from 'next/head';
import Announcements from '@containers/Announcements';
import withApollo from '@lib/withApollo';
const HomePage: React.FC<{}> = () => {
  return (
    <FadeIn>
      <Head>
        <title>{`${brand.brand} | ${brand.tagline}`}</title>
        <meta name="viewport" content="initial-scale=1.0, width=device-width" />
      </Head>
      <Announcements />
      <Home />
    </FadeIn>
  );
};

export default withApollo(HomePage);
