import React from 'react';
import MailchimpSubscribe from 'react-mailchimp-subscribe';
import { Form, Input } from 'antd';
import Button from '@components/atoms/Button';
import { Typography } from 'antd';
import styled from '@lib/styled';
import useMarketingIngestion from '@hooks/useMarketingIngestion';

const { Text, Title, Paragraph } = Typography;

export default function SubscribeMail() {
  const action =
    'https://openaba.us17.list-manage.com/subscribe/post?u=bc699acd4b369053ff04f8d4e&amp;id=4b17935337';

  const { ingestEmail } = useMarketingIngestion();

  const layout = {
    wrapperCol: { span: 24 }
  };

  const buttonItemLayout = {
    wrapperCol: { span: 14, offset: 1 }
  };

  const tailLayout = {
    wrapperCol: { offset: 8, span: 16 }
  };

  const Demo = () => {
    const onFinish = values => {
      console.log('Success:', values);
    };
  };

  const onFinishFailed = errorInfo => {
    console.log('Failed:', errorInfo);
  };

  return (
    <MailchimpSubscribe
      url={action}
      render={({ subscribe, status, message }) => (
        <div>
          <Form
            {...layout}
            name="basic"
            layout="inline"
            initialValues={{ remember: true }}
            onFinish={formData => {
              subscribe(formData), ingestEmail(formData.EMAIL);
            }}
            onFinishFailed={onFinishFailed}>
            <InputRow>
              <Form.Item
                name="EMAIL"
                // rules={[{ required: true, message: 'Please input your username!' }]}
              >
                <Input
                  style={{
                    borderRadius: 20,
                    minWidth: '20vw'
                  }}
                  size="large"
                  placeholder="Your best email!"
                />
              </Form.Item>
              <Form.Item {...buttonItemLayout}>
                <Button
                  type="primary"
                  htmlType="submit"
                  style={{ minWidth: '10vw' }}
                  shape="round"
                  size="large">
                  Submit
                </Button>
              </Form.Item>
            </InputRow>
          </Form>
          {/* {status === "sending" && <div style={{ color: "blue" }}>sending...</div>} */}
          {status === 'error' && (
            <div
              style={{ color: 'red' }}
              dangerouslySetInnerHTML={{ __html: message }}
            />
          )}
          {status === 'success' && (
            <div style={{ color: 'green' }}>Thank you for subscribing!</div>
          )}
        </div>
      )}
    />
  );
}

const InputRow = styled.div`
  display: flex;
  flex-direction: row;
  justify-content: flex-start;
  align-items: center;
`;
