import React from 'react';
import { useSpring, animated } from 'react-spring';

type FadeInProps = {};

const AContainer = animated.div;

const FadeIn: React.FC<FadeInProps> = ({ children }) => {
  let { opacity } = useSpring({
    from: { opacity: 0 },
    opacity: 1,
    delay: 100
  });

  return <AContainer style={{ opacity }}>{children}</AContainer>;
};

export default FadeIn;
