import React from 'react';
import { primaryColor } from '@lib/theme';
import styled from '@lib/styled';
import { AspectRatioBox, Box } from '@chakra-ui/core';
import { useDebouncedCallback } from 'use-debounce';

type TileProps = {
  buttons?: React.ReactNode;
  content?: React.ReactNode;
  overlay?: boolean;
  onClick?: () => void;
  size?: number;
  inactiveOverlayOpacity?: number;
  overlayTint?: string;
  style?: object;
};

export default function Tile({
  buttons,
  overlay = true,
  onClick,
  content,
  overlayTint,
  style,
  inactiveOverlayOpacity = 0,
  size = 150
}: TileProps) {
  const [onClick_] = useDebouncedCallback(
    () => {
      onClick && onClick();
    },
    100,
    { leading: true, maxWait: 250 }
  );

  const Container = onClick ? ActiveContainer : InactiveContainer;
  return (
    <Container
      style={{ cursor: 'pointer', ...style }}
      size={size}
      overlayTint={overlayTint}
      overlay={overlay}
      onClick={onClick_}
      onTouchStart={e => {
        e.preventDefault();
        onClick_();
      }}>
      {overlay && (
        <Overlay
          // onClick={onClick_}
          // onTouchStartCapture={onClick_}
          // onSelect={onClick_}
          // onTouchStart={onClick_}
          overlayTint={overlayTint}
          inactiveOverlayOpacity={inactiveOverlayOpacity}
          size={size}>
          {buttons}
        </Overlay>
      )}
      <AspectRatioBox
        onClick={onClick_}
        style={{ cursor: 'pointer' }}
        maxW={`${size}px`}
        ratio={1}>
        <Box onClick={onClick_} style={{ cursor: 'pointer' }} size="sm">
          {content}
        </Box>
      </AspectRatioBox>
    </Container>
  );
}

export type SizeProps = {
  size: number;
  overlay?: boolean;
  inactiveOverlayOpacity?: number;
  overlayTint?: string;
};

const InactiveContainer = styled.div<SizeProps>`
  position: relative;
  display: inline-block;
  overflow: hidden;
  margin-right: 12px;
  margin-bottom: 4px;
  border-radius: 8px;
  height: ${({ size }) => `${size + 0}px`};
  width: ${({ size }) => `${size + 0}px`};
  -webkit-box-shadow: 0px 6px 24px -15px rgba(0, 0, 0, 0.55);
  -moz-box-shadow: 0px 6px 24px -15px rgba(0, 0, 0, 0.55);
  box-shadow: 0px 6px 24px -15px rgba(0, 0, 0, 0.55);
  :hover {
    background: ${({ overlayTint, overlay }) =>
      overlay && (overlayTint ?? primaryColor(0.5))};
    -webkit-box-shadow: 0px 6px 30px -15px rgba(0, 0, 0, 1);
    -moz-box-shadow: 0px 6px 30px -15px rgba(0, 0, 0, 1);
    box-shadow: 0px 6px 30px -15px rgba(0, 0, 0, 1);
  }
  transition: box-shadow 0.3s ease-in-out;
`;

const ActiveContainer = styled.a<SizeProps>`
  position: relative;
  display: inline-block;
  overflow: hidden;
  margin-right: 12px;
  margin-bottom: 4px;
  border-radius: 8px;
  height: ${({ size }) => `${size + 0}px`};
  width: ${({ size }) => `${size + 0}px`};
  -webkit-box-shadow: 0px 6px 24px -15px rgba(0, 0, 0, 0.55);
  -moz-box-shadow: 0px 6px 24px -15px rgba(0, 0, 0, 0.55);
  box-shadow: 0px 6px 24px -15px rgba(0, 0, 0, 0.55);
  :hover {
    background: ${({ overlayTint, overlay }) =>
      overlay && (overlayTint ?? primaryColor(0.5))};
    -webkit-box-shadow: 0px 6px 30px -15px rgba(0, 0, 0, 1);
    -moz-box-shadow: 0px 6px 30px -15px rgba(0, 0, 0, 1);
    box-shadow: 0px 6px 30px -15px rgba(0, 0, 0, 1);
  }
  padding: 0;
  transition: box-shadow 0.3s ease-in-out;
  background: none;
  color: inherit;
  border: none;
  padding: 0;
  font: inherit;
  cursor: pointer;
  outline: inherit;
`;

const Overlay = styled.div<SizeProps>`
  position: absolute;
  width: ${({ size }) => `${size}px`};
  height: ${({ size }) => `${size}px`};
  display: flex;
  justify-content: space-around;
  align-items: center;
  background: ${({ overlayTint }) => overlayTint ?? primaryColor(0.5)};
  opacity: ${({ inactiveOverlayOpacity }) => inactiveOverlayOpacity ?? 0};
  z-index: 1;
  border-radius: 8px;
  :hover {
    opacity: 1;
  }
  color: inherit;
  border: none;
  padding: 0;
  font: inherit;
  cursor: pointer;
  outline: inherit;
`;
